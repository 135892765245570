<template>
  <div class="flex flex-col">
    <div class="flex flex-col lg:flex-row gap-3 lg:gap-6">
      <!-- Obrazek po lewej na desktopie -->
      <div class="w-2/5 hidden lg:block">
        <slot name="picture"></slot>
      </div>
      <!-- Tytul po lewej i obrazek po prawej na mobile -->
      <div class="w-full lg:hidden flex flex-row gap-3 lg:gap-6">
        <div class="w-3/4 font-bold flex h-max items-end text-lg">
          <slot name="title"></slot>
        </div>
        <div class="w-1/4">
          <slot name="picture"></slot>
        </div>
      </div>
      <!-- Tresc strony -->
      <div class="w-full lg:w-3/5 flex flex-col gap-3 lg:h-max">
        <!-- Tytul na desktopie -->
        <div class="hidden lg:block font-bold min-h-min text-lg">
          <slot name="title"></slot>
        </div>
        <div class="leading-normal lg:min-h-max my-auto">
          <slot name="content"></slot>
        </div>

        <!-- Przyciski -->
        <div class="w-full flex flex-row h-min justify-between mt-3">
          <div class="flex flex-row w-full text-sm items-center justify-start whitespace-nowrap">
            <slot name="bottom-left"></slot>
          </div>
          <div class="flex flex-row w-full text-sm items-center justify-end whitespace-nowrap">
            <slot name="bottom-right"></slot>
          </div>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
export default {
	name: "RegisterCard"
}
</script>

<style scoped lang="scss">

</style>